<template>
  <b-field
    :label="label"
    :label-for="label"
    :class="{ compact }"
    >
    <b-dropdown
    aria-role="list"
    :mobile-modal="false"
    :scrollable="isScrollable"
    :max-height="maxHeight"
    :expanded="expanded"
    :disabled="loading"
    :loading="true"
    v-model="selectedItemValue"
    >
      <template #trigger>
        <button
          class="button"
          :class="{ 'loading': loading, 'is-fullwidth': expanded }"
          >
          <slot
          name="selectedItem"
          :selectedItem="selectedItem"
          >
            <b-icon
              :icon="`${selectedItem[iconProperty]}`"
              :style="`color: ${selectedItem[colorProperty]};`"
              >
            </b-icon>
          </slot>
          <span>{{ selectedItem[displayProperty] }}</span>
        </button>
      </template>

      <!-- Note: The :value attribute is what is fed to the <b-dropdown /> v-model
      after an item is selected -->
      <b-dropdown-item
        v-for="(item, index) in list"
        :key="index"
        :value="item[valueProperty]"
        aria-role="listitem"
        >
        <div class="media">
          <slot
          name="item"
          :item="item"
          >
            <b-icon
              class="media-left" :icon="`${item[iconProperty]}`"
              :style="`color: ${item[colorProperty]};`"
              >
            </b-icon>
          </slot>
          <div class="media-content">
            <div class="has-text-weight-semibold">{{ item[displayProperty] }}</div>
          </div>
        </div>
      </b-dropdown-item>
    </b-dropdown>
  </b-field>
</template>

<script>
import {
  find, isEmpty, isObject, every
} from 'lodash';

export default {
  name: 'RequesterDropdownField',
  props: {
    label: {
      type: String,
      required: true,
      validator: value => !!value,
    },
    expanded: {
      type: Boolean,
      default: false,
    },
    compact: {
      type: Boolean,
      default: false,
    },

    list: {
      type: Array,
      reuired: true,
      // Is every array element an object?
      validator: value => every(value, isObject)
    },
    initialValue: {
      type: String,
      default: ''
    },
    valueProperty: {
      type: String,
      required: true
    },
    displayProperty: {
      type: String,
      required: true
    },
    iconProperty: {
      type: String,
    },
    colorProperty: {
      type: String,
    },
    displayFormatter: {
      type: Function,
      default: value => value,
    },

    targetEntity: {
      type: Object,
      required: true,
    },
    targetEntityProperty: {
      type: String,
      required: true
    },
    storeAction: {
      type: String,
      required: true,
      validator: value => !!value,
    },
  },
  data() {
    return {
      isPublic: true,
      isScrollable: true,
      maxHeight: 200,
      selectedItemValue: '',
      previousValue: '',
      loading: false
    };
  },
  computed: {
    /* Resorted to using computed ('selectedItem') and watch ('selectedItem')
    as Beufy <b-dropdown /> "change" event did not behave as expected.
    At the time of responding to the "change" event, accessing the 'selectedItemValue' value
    is that of the previous value, not the newly selected value... */
    selectedItem() {
      return find(this.list, item => item[this.valueProperty] === this.selectedItemValue)
        // for the case "initalValue" prop isn't provided
        || {};
    },
  },
  watch: {
    selectedItem(newOption, oldOption) {
      if (
        !isEmpty(oldOption)
        && oldOption[this.valueProperty] !== newOption[this.valueProperty]
      ) {
        this.requestApi(newOption[this.valueProperty]);
      }
    },
  },
  methods: {
    displayFormatterExecutor(option) {
      return this.displayFormatter(option);
    },
    requestApi(selectedValue) {
      const {
        targetEntity,
        targetEntityProperty,
        storeAction,
        previousValue,
      } = this;
      const { _id } = targetEntity;
      this.loading = true;
      this.$store.dispatch(storeAction,
        {
          _id,
          [targetEntityProperty]: selectedValue,
          previousValue,
        })
        .then(() => {
          this.loading = false;
          this.previousValue = selectedValue;
        })
        .catch(error => console.error(error));
    },
  },
  created() {
    this.selectedItemValue = this.initialValue;
    this.previousValue = this.initialValue;
  }
};
</script>

<style lang="scss" scoped>
  .field {
    &.compact {
      ::v-deep .label {
        margin-left: 10px;
        margin-bottom: 0;
        font-size: 12px;
        color: #808080;
        text-transform: capitalize;
      }
      .dropdown {
        > .dropdown-trigger > button {
          border-color: transparent;
          &:hover {
          border-color: #dbdbdb;
          }
          &:focus {
            border-color: #8c67ef;
          }
        }
      }
    }

    .dropdown {
      > .dropdown-trigger {
        > button {
          padding-right: 40px;
          &.loading {
            &::after {
              top: calc(50% - .5em);
              right: .75em;
              margin-top: 0;
              position: absolute;
              transform: none;
              animation: spinAround .5s infinite linear;
              border: 2px solid #dbdbdb;
              border-radius: 9999px;
              border-right-color: transparent;
              border-top-color: transparent;
              content: "";
              display: block;
              height: 1em;
              width: 1em;
            }
          }
          &.is-fullwidth {
            justify-content: left;
          }
        }
      }
      > .dropdown-menu {
        .media-content {
          > div {
            margin-top: 2px;
          }
        }
      }
    }
  }
</style>
