<template>
  <article
    class="media">
    <div class="media-left">
        <!-- <img src="https://img.icons8.com/color/42/000000/geisha.png"/> -->
        <!-- <img src="https://via.placeholder.com/64" alt=""> -->
    <Avatar
      :fullName="comment.userFullName"
      :size="3"
    />
    </div>
    <div class="media-content">
      <div
        v-if="readOnly"
        class="content"
        >
        <div>
          <strong>{{ comment.userFullName }}</strong>
          {{ comment.createdAt }}
          <span class="is-italic">
            {{ comment.createdAt !== comment.updatedAt ? '(edited)' : '' }}
          </span>
        </div>
        <div>
          {{ comment.body }}
        </div>
        <br
          v-if="CURRENT_USER._id === comment.user"
          >
        <div
          class="buttons"
          v-if="CURRENT_USER._id === comment.user"
          >
          <b-button
            type="is-primary is-small is-text"
            @click="handleEditCommentItem"
            >Edit</b-button>
          <b-button
            type="is-light is-small is-text"
            @click="handleDeleteCommentItem(comment._id)"
            >Delete</b-button>
        </div>
      </div>
      <div
        v-else
        class="field"
        >
        <div class="control">
          <!-- <textarea
          class="textarea"
          placeholder="Add a comment..."
          v-model="commentText"
          @focus="handleMediaInputClicked"
          @click="handleMediaInputClicked"
        ></textarea> -->
          <b-input
          type="textarea"
          :value="comment.body"
          ref="textarea"
          @hook:mounted="childMounted"
        ></b-input>
        </div>
        <br
          >
        <div
          class="buttons"
          >
          <b-button
            type="is-primary has-text-weight-medium"
            @click="handleSaveCommentItem"
            >Save</b-button>
          <b-button
            type="is-light has-text-weight-medium"
            @click="handleCancelAddComment"
            >Cancel</b-button>
        </div>
      </div>
    </div>
  </article>
</template>

<script>
import { mapGetters } from 'vuex';
import { CONSTANTS as COMMENT_CONSTANTS } from '@/store/modules/comment/comment.constants';
import { CONSTANTS as USER_CONSTANTS } from '@/store/modules/user/user.constants';
import { CONSTANTS as STORY_CONSTANTS } from '@/store/modules/story/story.constants';
import Avatar from '../pureComponents/Avatar.component.vue';

export default {
  name: 'CommentItemComponent',
  components: { Avatar },
  data() {
    return {
      readOnly: true,
    };
  },
  props: {
    singleStory: {
      type: Object,
      required: true
    },
    comment: {
      type: Object,
      required: true
    }
  },
  computed: {
    ...mapGetters([
      USER_CONSTANTS.GETTERS.CURRENT_USER,
    ]),
    commentText: {
      get() {
        return this.comment.body;
      },
      // https://itnext.io/anyway-this-is-how-to-use-v-model-with-vuex-computed-setter-in-action-320eb682c976
      // https://markus.oberlehner.net/blog/form-fields-two-way-data-binding-and-vuex/
      set(value) {
        // defining the setter is required or vue complains.
        // setup to do nothing
        return value;
      }
    }
  },
  methods: {
    handleCancelAddComment() {
      this.readOnly = true;
    },
    handleDeleteCommentItem(commentId) {
      // eslint-disable-next-line
      if (window.confirm('Are you sure you want to delete this comment?')) {
        this.$store
          .dispatch(COMMENT_CONSTANTS.ACTIONS.STORY_COMMENT_DELETE, commentId)
          .then(() => {
            this.$store
              .dispatch(STORY_CONSTANTS.ACTIONS.STORY_COMMENTS_REQUEST, this.singleStory._id)
              .catch(error => console.error(error));
          })
          .catch(error => console.error(error));
      }
    },
    handleEditCommentItem() {
      this.readOnly = false;
      // https://forum.vuejs.org/t/reference-elements-when-they-rendered-with-v-if/16474
      // https://stackoverflow.com/questions/46040138/cannot-access-element-shown-by-v-if-in-component-mounted-callback
      // this.$nextTick(() => {
      //   this.$refs.textarea.focus();
      // });
    },
    childMounted() {
      // https://dev.to/f3ltron/vuejs-listen-lifecycle-hook-from-the-child-component-2hp9
      // https://alligator.io/vuejs/component-event-hooks/
      this.$refs.textarea.focus();
    },
    handleSaveCommentItem() {
      this.$store
        .dispatch(COMMENT_CONSTANTS.ACTIONS.STORY_COMMENT_UPDATE, {
          commentId: this.comment._id,
          body: this.$refs.textarea.$refs.textarea.value
        })
        .then(() => {
          this.$store
            .dispatch(STORY_CONSTANTS.ACTIONS.STORY_COMMENTS_REQUEST, this.singleStory._id)
            .then(() => {
              this.readOnly = true;
            })
            .catch(error => console.error(error));
        })
        .catch(error => console.error(error));
    },
  }
};
</script>

<style>

</style>
