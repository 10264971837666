<style lang="scss">
.stories {
  height: 100%;
  &__content {
    height: 100%;
    min-width: 1023px;
  }
  &__header {
    display: flex;
    > .column {
      background: #eee;
      border-radius: 4px 4px 0 0;
      margin: 0 5px;
    }
  }
  &__board {
    display: flex;
    height: calc(100% - 130px);
    > .column {
      background: hsl(0, 0%, 96%);
      margin: 0 5px;
      border-radius: 0 0 4px 4px;
      overflow-x: hidden;
      overflow-y: auto;
    }
  }
}
.list-group {
  height: 100%;
}
// .chosen {
//   opacity: 1;
// }
.ghost {
  opacity: 0.5;
}
// .drag {
//   opacity: 1;
// }
// https://github.com/SortableJS/Sortable#ghostclass-option
// https://github.com/SortableJS/Sortable/issues/1268
.fallback {
  opacity: 1 !important;
  transform: rotate(3deg);
}
.story {
  display: block;
  position: relative;
  padding: 11px 11px 9px;
  background: #fff;
  border-radius: 5px;
  border-left-width: 8px;
  border-left-width: 5px solid #fbb81b;
  box-sizing: border-box;
  box-shadow: 0 2px 4px rgba(0,0,0,.2);
  border-left: 5px solid #efb043;
  color: #333;
  height: 100px;
  margin: 0 0 10px 0;
  cursor: pointer;
  transition: background-color .1s, opacity 250ms;
}
.story-title {
  font-weight: 700;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.story-body {
  display: flex;
  align-items: center;
  justify-content: space-between;

}
.story-description {
  line-height: 18px;
  margin-bottom: 6px;
  word-wrap: break-word;
}
</style>

<template>
  <div class="stories">
    <b-notification
      v-if="isLoading"
      style="height: 100%;"
      class="is-white"
      :closable="false">
      <b-loading
        :is-full-page="false"
        v-model="isLoading">
        <b-icon
          icon="sync"
          size="is-large"
          custom-class="fa-spin fa-10x">
        </b-icon>
        <div
          style="position: absolute; margin-top: 100px; font-size: 20px;"
          class="has-text-grey"
          >
          Loading...
        </div>
      </b-loading>
    </b-notification>
    <div
      v-if="!isLoading"
      class="stories__content">
    <div class="content">
        <button class="button is-primary is-medium"
          @click="isComponentModalActive = true">
          Create a Story
        </button>
        <b-modal :active.sync="isComponentModalActive"
            has-modal-card
            full-screen
            trap-focus
            aria-role="dialog"
            aria-modal>
          <modal-form @storyCreated="storyCreated"></modal-form>
        </b-modal>
      </div>
      <!-- <vue-json-pretty
        :data="sortedUserStoriesGroupedByStatus"
        :highlight-mouseover-node="true">
      </vue-json-pretty> -->
      <div class="stories__header">
        <div class="column"
              v-for="(status) in BOARD_STATUSES" :key="status._id">
          <h3 class="has-text-grey-dark has-text-weight-semibold">{{ status.title }}</h3>
      </div>
      </div>
      <div class="stories__board">
        <div class="column"
              v-for="(status) in BOARD_STATUSES" :key="status._id">
          <draggable
            class="list-group"
            :data-status-type="status._id"
            :list="lists[status._id]"
            group="stories"
            chosen-class="chosen"
            ghost-class="ghost"
            drag-class="drag"
            :force-fallback="true"
            fallback-class="fallback"
            :animation="150"
            draggable=".story"
            @end="end">
            <router-link
              class="story"
              v-for="(story) in lists[status._id]"
              :key="story._id"
              :data-story-id="story._id"
              :to="`/${ACTIVE_WORKSPACE.title}/stories/${story._id}`"
              :class="[replaceHashWithUnderscore(story.color)]"
              >
              <div class="story-title">{{story.title}}</div>
              <div class="story-body">
                <div>
                  <b-icon
                    class="mr-3"
                    :icon="transformStoryTypesWithIconsToObjectFormat[story.storyType].icon"
                    :style="
                    `color: ${transformStoryTypesWithIconsToObjectFormat[story.storyType].color}`
                    "
                  >
                  </b-icon>
                  <b-icon
                    :icon="transformStoryPrioritiesWithIconsToObjectFormat[story.priority].icon"
                    :style="
                    `
                    color: ${transformStoryPrioritiesWithIconsToObjectFormat[story.priority].color}`
                    "
                  >
                  </b-icon>
                </div>
                <b-tooltip
                  position="is-bottom"
                  type="is-dark"
                  multilined
                  :delay="500"
                  >
                  <Avatar
                    v-if="story.owner"
                    :fullName="activeWorkspaceMembersIdToFullName[story.owner]"
                    :size="3"
                  />
                  <template v-slot:content>
                    <Avatar
                      v-if="story.owner"
                      :fullName="activeWorkspaceMembersIdToFullName[story.owner]"
                    />
                    <h1 class="is-light"> {{ activeWorkspaceMembersIdToFullName[story.owner] }}</h1>
                    <h1 class="is-light"> {{ activeWorkspaceMembersIdToEmail[story.owner] }}</h1>
                  </template>
                </b-tooltip>
                <!-- <span class="story-description">{{ story.description }}</span> -->
              </div>
            </router-link>
          </draggable>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  map, forEach, groupBy, sortBy,
} from 'lodash';
import { mapGetters } from 'vuex';
import draggable from 'vuedraggable';
import { CONSTANTS as STORY_CONSTANTS } from '@/store/modules/story/story.constants';
import { CONSTANTS as PROJECT_CONSTANTS, } from '@/store/modules/project/project.constants';
import { CONSTANTS as BOARD_CONSTANTS } from '@/store/modules/board/board.constants';
import { CONSTANTS as WORKSPACE_CONSTANTS } from '@/store/modules/workspace/workspace.constants';
import { CONSTANTS as USER_CONSTANTS } from '@/store/modules/user/user.constants';
import { replaceHashWithUnderscore, } from '../../utils/utils';
import ModalForm from './CreateStoryForm.vue';
import Avatar from '../../components/pureComponents/Avatar.component.vue';

const clone = require('rfdc')({ proto: false });

export default {
  name: 'StoriesView',
  components: {
    ModalForm,
    draggable,
    Avatar,
  },
  data() {
    return {
      isLoading: true,
      isComponentModalActive: false,
      lists: {},
    };
  },
  computed: {
    ...mapGetters([
      WORKSPACE_CONSTANTS.GETTERS.ACTIVE_WORKSPACE,
      WORKSPACE_CONSTANTS.GETTERS.activeWorkspaceMembers,
      BOARD_CONSTANTS.GETTERS.BOARD,
      BOARD_CONSTANTS.GETTERS.BOARD_STATUSES,
      PROJECT_CONSTANTS.GETTERS.ACTIVE_WORKSPACE_PROJECTS,
      STORY_CONSTANTS.GETTERS.USER_STORIES,
      STORY_CONSTANTS.GETTERS.STORY_TYPES_WITH_ICON,
      STORY_CONSTANTS.GETTERS.STORY_PRIORITIES_WITH_ICON,
    ]),
    activeWorkspaceMembersIdToFullName() {
      const membersHashMap = {};
      forEach(this.activeWorkspaceMembers, (member) => {
        membersHashMap[member._id] = member.fullName;
      });

      return membersHashMap;
    },
    activeWorkspaceMembersIdToEmail() {
      const membersHashMap = {};
      forEach(this.activeWorkspaceMembers, (member) => {
        membersHashMap[member._id] = member.email;
      });

      return membersHashMap;
    },
    transformedUserStories() {
      let copy = clone(this.USER_STORIES);

      copy = map(copy, (story) => {
        let boardStatusObject;

        forEach(this.BOARD_STATUSES, (boardStatus) => {
          if (boardStatus._id === story.status) boardStatusObject = boardStatus;
        });

        forEach(boardStatusObject.stories, (boardItem) => {
          if (boardItem.story === story._id) {
            // eslint-disable-next-line no-param-reassign
            story.position = boardItem.position;
          }
        });

        return story;
      });

      copy = map(copy, (story) => {
        this.ACTIVE_WORKSPACE_PROJECTS.forEach((project) => {
          if (story.project === project._id) {
            // eslint-disable-next-line no-param-reassign
            story.color = project.color;
          }
        });

        return story;
      });

      return copy;
    },
    userStoriesGroupedByStatus() {
      return groupBy(this.transformedUserStories, 'status');
    },
    /**
     * How the data structure looks like
     * {
     *   "6122816fe1c3051fb8cea56b6122816fe1c3051fb8cea56c": [{...}, {...}, {...}]
     *   "6122816fe1c3051fb8cea56c": [{...}, {...}],
     *   ...
     * }
     * e.g.: "6122816fe1c3051fb8cea56c" is the _id of an status object
     * e.g.: {...} is a story object
     */
    sortedUserStoriesGroupedByStatus() {
      const copy = clone(this.userStoriesGroupedByStatus);

      forEach(this.userStoriesGroupedByStatus, (status, key) => {
        copy[key] = sortBy(status, 'position');
      });

      return copy;
    },
    boardStatusesIDs() {
      return map(this.BOARD_STATUSES, status => status._id);
    },
    transformStoryTypesWithIconsToObjectFormat() {
      const obj = {};

      this.STORY_TYPES_WITH_ICON.forEach((element) => {
        obj[element.name] = {
          icon: element.icon,
          color: element.color
        };
      });

      return obj;
    },
    transformStoryPrioritiesWithIconsToObjectFormat() {
      const obj = {};

      this.STORY_PRIORITIES_WITH_ICON.forEach((element) => {
        obj[element.name] = {
          icon: element.icon,
          color: element.color
        };
      });

      return obj;
    },
  },
  methods: {
    replaceHashWithUnderscore,
    storyCreated() {
      const { sortedUserStoriesGroupedByStatus, boardStatusesIDs } = this;
      boardStatusesIDs.forEach((status) => {
        if (!(status in sortedUserStoriesGroupedByStatus)) {
          sortedUserStoriesGroupedByStatus[status] = [];
        }
      });
      this.lists = sortedUserStoriesGroupedByStatus;
    },
    end(event) {
      const {
        item, from, to, oldIndex, newIndex
      } = event;
      const _id = item.dataset.storyId;
      const oldStatus = from.dataset.statusType;
      const newStatus = to.dataset.statusType;

      this.$store.dispatch(STORY_CONSTANTS.ACTIONS.STORY_UPDATE, {
        _id,
        status: newStatus,
      })
        .then(() => {
          this.$store.dispatch(
            BOARD_CONSTANTS.ACTIONS.BOARD_UPDATE,
            {
              boardID: this.BOARD._id,
              story: _id,
              oldStatus,
              newStatus,
              oldPosition: oldIndex,
              newPosition: newIndex
            }
          );
        })
        .catch(error => console.error(error));
    }
  },
  created() {
    this.$store
      .dispatch(USER_CONSTANTS.ACTIONS.USERS_REQUEST)
      .catch(error => console.error(error));

    this.$store.dispatch(WORKSPACE_CONSTANTS.ACTIONS.WORKSPACE_PROJECTS_REQUEST)
      .catch(error => console.error(error));
    this.$store.dispatch(WORKSPACE_CONSTANTS.ACTIONS.WORKSPACE_STORIES_REQUEST)
      .then(() => {
        this.$store.dispatch(WORKSPACE_CONSTANTS.ACTIONS.WORKSPACE_BOARD_REQUEST)
          .then(() => {
            const { sortedUserStoriesGroupedByStatus, boardStatusesIDs } = this;
            boardStatusesIDs.forEach((status) => {
              /* NOTE: We are mutating the sortedUserStoriesGroupedByStatus getter here.
                Not sure if affecting the sortedUserStoriesGroupedByStatus array is permanent.
                Not sure if it is ok or is anti-pattern.
                Tested, it is not affecting the sortedUserStoriesGroupedByStatus array permanently,
                because (I think) everytime a story is moved, the USER_STORIES getter is updated,
                and consequently the sortedUserStoriesGroupedByStatus getter is updated.
              */
              if (!(status in sortedUserStoriesGroupedByStatus)) {
                sortedUserStoriesGroupedByStatus[status] = [];
              }
            });
            this.isLoading = false;
            this.lists = sortedUserStoriesGroupedByStatus;
          })
          .catch(error => console.error(error));
      })
      .catch(error => console.error(error));
  },
};
</script>
