<template>
<section>
  <CommentItem
    v-for="(comment, index) in modifiedCurrentStoryComments"
    v-bind:key="index"
    :singleStory="singleStory"
    :comment="comment"
    >
  </CommentItem>
  <article class="media">
    <div class="media-left">
      <p class="image is-64x64x">
        <!-- <img src="https://img.icons8.com/color/42/000000/geisha.png"/> -->
        <!-- <img src="https://via.placeholder.com/64" alt=""> -->
      <Avatar
        :fullName="CURRENT_USER.fullName"
        :size="3"
      />
      </p>
    </div>
    <div class="media-content">
      <div class="field">
        <div class="control">
          <textarea
          class="textarea"
          :rows="rows"
          placeholder="Add a comment..."
          v-model="commentText"
          @focus="handleMediaInputClicked"
          @click="handleMediaInputClicked"
        ></textarea>
        </div>
        <br
          v-if="rows !== 1"
          >
        <div
          class="buttons"
          v-if="rows !== 1"
          >
          <b-button
            type="is-primary has-text-weight-medium"
            @click="handleAddComment"
            >Save</b-button>
          <b-button
            type="is-light has-text-weight-medium"
            @click="handleCancelAddComment"
            >Cancel</b-button>
        </div>
      </div>
    </div>
  </article>
</section>
</template>

<script>
import { forEach } from 'lodash';
import { mapGetters } from 'vuex';
import { CONSTANTS as COMMENT_CONSTANTS } from '@/store/modules/comment/comment.constants';
import { CONSTANTS as USER_CONSTANTS } from '@/store/modules/user/user.constants';
import { CONSTANTS as STORY_CONSTANTS } from '@/store/modules/story/story.constants';
import CommentItem from '@/components/containerComponents/CommentItem.component.vue';
import Avatar from '@/components/pureComponents/Avatar.component.vue';

const clone = require('rfdc')({ proto: false });

export default {
  name: 'CommentComponent',
  components: { CommentItem, Avatar },
  data() {
    return {
      rows: 1,
      commentText: ''
    };
  },
  props: {
    singleStory: {
      type: Object,
      required: true
    }
  },
  computed: {
    ...mapGetters([
      USER_CONSTANTS.GETTERS.CURRENT_USER,
      COMMENT_CONSTANTS.GETTERS.CURRENT_STORY_COMMENTS
    ]),
    creatorIdToCreatorEmailMapping() {
      const creatorIdToCreatorEmailObject = {};
      const users = this.$store.getters[USER_CONSTANTS.GETTERS.USERS];

      forEach(users, (user) => {
        creatorIdToCreatorEmailObject[user._id] = user.email;
      });

      return creatorIdToCreatorEmailObject;
    },
    creatorIdToCreatorFullNameMapping() {
      const creatorIdToCreatorFullNameObject = {};
      const users = this.$store.getters[USER_CONSTANTS.GETTERS.USERS];

      forEach(users, (user) => {
        creatorIdToCreatorFullNameObject[user._id] = user.fullName;
      });

      return creatorIdToCreatorFullNameObject;
    },
    modifiedCurrentStoryComments() {
      const copy = clone(this.CURRENT_STORY_COMMENTS);
      return copy.map((comment) => {
        // fixes 'no-param-reassign' eslint error
        // https://stackoverflow.com/questions/35637770/how-to-avoid-no-param-reassign-when-setting-a-property-on-a-dom-object
        const commentCopy = Object.assign({}, comment);

        commentCopy.userEmail = this.creatorIdToCreatorEmailMapping[comment.user];
        commentCopy.userFullName = this.creatorIdToCreatorFullNameMapping[comment.user];
        return commentCopy;
      });
    },
  },
  methods: {
    handleMediaInputClicked() {
      if (this.rows === 1) {
        this.rows = 4;
        this.commentText = '';
      }
    },
    handleAddComment() {
      const commentData = {};
      const { commentText } = this;

      if (commentText) {
        commentData.body = commentText;
        commentData.story = this.singleStory._id;
        commentData.user = this.$store.getters[USER_CONSTANTS.GETTERS.CURRENT_USER]._id;

        this.$store
          .dispatch(COMMENT_CONSTANTS.ACTIONS.STORY_COMMENT_CREATE, commentData)
          .then(() => {
            this.rows = 1;
            this.commentText = '';

            this.$store
              .dispatch(STORY_CONSTANTS.ACTIONS.STORY_COMMENTS_REQUEST, this.singleStory._id)
              .catch(error => console.error(error));
          })
          .catch(error => console.error(error));
      }
    },
    handleCancelAddComment() {
      this.rows = 1;
      this.commentText = '';
    },
  },
  created() {
    this.$store
      .dispatch(STORY_CONSTANTS.ACTIONS.STORY_COMMENTS_REQUEST, this.singleStory._id)
      .catch(error => console.error(error));
  },
};
</script>

<style lang="scss" scoped>
  // .media-content {
  //   > .content {
  //     .buttons {
  //       display: none;
  //     }
  //     &:hover {
  //       .buttons {
  //         display: block;
  //       }
  //     }
  //   }
  // }
  .media + .media {
    border-top: none;
  }
</style>
