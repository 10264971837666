<template>
  <div class="story-page">
    <section>
      <!-- <vue-json-pretty
        :data="USER_STORY"
        :highlight-mouseover-node="true">
      </vue-json-pretty> -->
      <div class="content">
        <div class="columns is-fullheight">
          <div class="column is-8">
            <EditableField
              class="is-size-3 has-text-weight-bold"
              required
              placeholder="something here"
              :value="USER_STORY.title"
              @confirm="confirm('title', $event)"
              >
                {{USER_STORY.title}}
                <span class="fa fa-xs fa-pencil-alt is-grey-light"></span>
            </EditableField>
            <br>
            <Editor
              :content="USER_STORY.description"
              @editor:save="confirm('description', $event)"
            >
            </Editor>
            <br>
            <br>
            <div class="has-text-weight-medium">Comments</div>
            <hr>
            <Comment
            :singleStory="USER_STORY"
            >
            </Comment>
          </div>
          <div class="column is-4 is-sidebar-menu" style="border-left: 1px solid #ccc;">
            <RequesterDropdownField
              label="Status"
              :expanded="expanded"
              :compact="compact"
              :list="BOARD_STATUSES"
              :initialValue="USER_STORY.status"
              valueProperty="_id"
              displayProperty="title"
              iconProperty="icon"
              colorProperty="color"
              :targetEntity="USER_STORY"
              targetEntityProperty="status"
              :storeAction="BOARD_CONSTANTS.ACTIONS.BOARD_TRANSITION_STORY_STATUS"
            />
            <RequesterDropdownField
              label="Assignee"
              :expanded="expanded"
              :compact="compact"
              :list="activeWorkspaceMembers"
              :initialValue="USER_STORY.owner"
              valueProperty="_id"
              displayProperty="fullName"
              :targetEntity="USER_STORY"
              targetEntityProperty="owner"
              :storeAction="STORY_CONSTANTS.ACTIONS.STORY_UPDATE"
            >
              <template v-slot:selectedItem="slotProps">
                <span class="icon">
                  <Avatar
                    :fullName="slotProps.selectedItem.fullName"
                    :size="1"
                  />
                </span>
              </template>
              <template v-slot:item="slotProps">
                <span class="icon media-left">
                  <Avatar
                    :fullName="slotProps.item.fullName"
                    :size="1"
                  />
                </span>
              </template>
            </RequesterDropdownField>
            <RequesterDropdownField
              label="Type"
              :expanded="expanded"
              :compact="compact"
              :list="STORY_TYPES_WITH_ICON"
              :initialValue="USER_STORY.storyType"
              valueProperty="name"
              displayProperty="name"
              iconProperty="icon"
              colorProperty="color"
              :targetEntity="USER_STORY"
              targetEntityProperty="storyType"
              :storeAction="STORY_CONSTANTS.ACTIONS.STORY_UPDATE"
            />
            <RequesterDropdownField
              label="Priority"
              :expanded="expanded"
              :compact="compact"
              :list="STORY_PRIORITIES_WITH_ICON"
              :initialValue="USER_STORY.priority"
              valueProperty="name"
              displayProperty="name"
              iconProperty="icon"
              colorProperty="color"
              :targetEntity="USER_STORY"
              targetEntityProperty="priority"
              :storeAction="STORY_CONSTANTS.ACTIONS.STORY_UPDATE"
            />
            <RequesterDropdownField
              label="Project"
              :expanded="expanded"
              :compact="compact"
              :list="ACTIVE_WORKSPACE_PROJECTS"
              :initialValue="USER_STORY.project"
              valueProperty="_id"
              displayProperty="title"
              iconProperty="icon"
              colorProperty="color"
              :targetEntity="USER_STORY"
              targetEntityProperty="project"
              :storeAction="STORY_CONSTANTS.ACTIONS.STORY_UPDATE"
            />
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { map, } from 'lodash';
import { mapGetters } from 'vuex';
import store from '@/store';
import EditableField from '@/components/pureComponents/EditableField.component.vue';
import Comment from '@/components/containerComponents/Comment.component.vue';
import Editor from '@/components/containerComponents/Editor.component.vue';
import Avatar from '@/components/pureComponents/Avatar.component.vue';
import RequesterDropdownField from '@/components/containerComponents/requesterDropdownField.vue';
import { CONSTANTS as USER_CONSTANTS } from '@/store/modules/user/user.constants';
import { CONSTANTS as WORKSPACE_CONSTANTS, } from '@/store/modules/workspace/workspace.constants';
import { CONSTANTS as BOARD_CONSTANTS } from '@/store/modules/board/board.constants';
import { CONSTANTS as PROJECT_CONSTANTS, } from '@/store/modules/project/project.constants';
import { CONSTANTS as STORY_CONSTANTS } from '@/store/modules/story/story.constants';

export default {
  name: 'StoryView',
  components: {
    EditableField,
    Comment,
    Editor,
    RequesterDropdownField,
    Avatar,
  },
  data() {
    return {
      // So the template can have access to it
      STORY_CONSTANTS,
      BOARD_CONSTANTS,
      compact: false,
      expanded: true,
    };
  },
  computed: {
    ...mapGetters([
      USER_CONSTANTS.GETTERS.USERS,
      WORKSPACE_CONSTANTS.GETTERS.ACTIVE_WORKSPACE,
      WORKSPACE_CONSTANTS.GETTERS.activeWorkspaceMembers,
      BOARD_CONSTANTS.GETTERS.BOARD_STATUSES,
      PROJECT_CONSTANTS.GETTERS.ACTIVE_WORKSPACE_PROJECTS,
      STORY_CONSTANTS.GETTERS.USER_STORY,
      STORY_CONSTANTS.GETTERS.STORY_TYPES,
      STORY_CONSTANTS.GETTERS.STORY_TYPES_WITH_ICON,
      STORY_CONSTANTS.GETTERS.STORY_PRIORITIES,
      STORY_CONSTANTS.GETTERS.STORY_PRIORITIES_WITH_ICON,
    ]),
    storyID() {
      return this.$route.params._id;
    },
    USER_STORY: ({ storyID }) => store
      .getters[STORY_CONSTANTS.GETTERS.USER_STORY](storyID),
    boardStatusesTitles() {
      return map(this.BOARD_STATUSES, status => status.title);
    },
  },
  methods: {
    confirm(key, value) {
      const { storyID: _id } = this;
      this.$store.dispatch(STORY_CONSTANTS.ACTIONS.STORY_UPDATE, { _id, [key]: value })
        .catch(error => console.error(error));
    },
  },
  // https://stackoverflow.com/questions/58991194/why-change-data-in-beforecreated-created-beforemount-can-not-trigger-watch-in-vu
  async created() {
    const usersRequestPromise = this.$store
      .dispatch(USER_CONSTANTS.ACTIONS.USERS_REQUEST)
      .catch(error => console.error(error));

    const storiesRequestPromise = this.$store
      .dispatch(WORKSPACE_CONSTANTS.ACTIONS.WORKSPACE_STORIES_REQUEST)
      .catch(error => console.error(error));

    await Promise.all([usersRequestPromise, storiesRequestPromise]);
  },

};
</script>

<style lang="scss">
.story-page {
  .content {
    @media screen and (max-width: 768px) {
      .columns {
        display: flex;
        flex-direction: column-reverse;
      }
    }
  }
}

</style>
